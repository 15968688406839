export default {
    methods: {
        async loadData(clientId, callback) {
            var rs = await this.$store.dispatch('getPlanCardList', {
                clientId: clientId,
            })
            var list = []
            if(rs && rs['clientMedicalPlan']) {
                rs['clientMedicalPlan'].forEach((item)=>{
                    var filterData = list.find(d=>d.name == item.companyshortname)
                    if(!filterData) {
                        filterData = {
                            name: item.companyshortname,
                            values: []
                        }
                        list.push(filterData)
                    }
                    filterData.values.push(item)
                })
                callback && callback({
                    list: list
                }, null)
            } else {
                callback && callback({
                    list: list
                }, rs)
            }
        },
        async getDetail(clientId, medicalplanitemid, callback) {
            var rs = await this.$store.dispatch('getPlanCardDetail', {
                clientId: clientId,
                medicalplanitemid: medicalplanitemid
            })
            var list = Object.prototype.toString.call(rs) == '[object Array]' ? rs : []
            var error = Object.prototype.toString.call(rs) != '[object Array]' ? rs : null
            callback && callback({
                list: list
            }, error)
        }
    }
}