<template>
	<div class="card-plan">
        <div class="plan-list">
            <div class="plan-item" v-for="(item, i) in dataList" :key="i">
                <div class="plan-head" v-text="item.name"></div>
                <div class="plan-item-orders">
                    <div class="plan-item-order" v-for="plan in item.values" :key="plan.systemid">
                        <div class="name" v-text="plan.servicename"></div>
                        <div class="time">{{plan.medicalplanstartdate}}-{{plan.medicalplanenddate}}</div>
                        <div class="total">共計{{plan.serviceqtypurchased}}次</div>
                        <div class="free">剩餘{{plan.serviceqtyavailable}}次</div>
                        <div class="btn-detail" @click="showDetail(plan)">消費明細</div>
                    </div>
                </div>
            </div>
        </div>
		<loading v-if="loading"/>
		<plan-card-detail v-if="visible" :callback="onClose" :id="detailId" :title="title"/>
		<div class="load-finished" v-if="isLoadFinished">已全部加載</div>

	</div>
</template>

<script>
	import plan_card from '@/views/mixins/plan_card'
	import Loading from '@/layouts/Loading.vue'
	import PlanCardDetail from '@/layouts/PlanCardDetail.vue'
	export default {
		mixins: [
			plan_card
		],
		components: {
			Loading,
			PlanCardDetail
		},
		data(){
			return {
				isLoadFinished: false,
				loading: true,
				visible: false,
				style: '',
				title: '',
				dataList: [],
				detailId: null,
			}
		},
		mounted: function(){
			this.loadData(this.$store.getters.clientId, (data, error)=>{
				if(error) {
					this.loading = false
					this._toast.warning(this, error)
					return
				}
				this.loading = false
				this.dataList = data.list
				this.isLoadFinished = true
			})
		},
		methods: {
			onClose: function(){
				this.visible = false
				document.body.setAttribute('style', this.style)
			},
			showDetail: function(data) {
				this.detailId = data.medicalplanitemid
				this.title = data.servicename
				this.visible = true
				this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card-plan {
		position: relative;
		height: 450px;
		overflow-y: scroll;
	}

	.plan-item .plan-head {
		color: #969696;
		font-weight: 400;
		font-size: 18px;
		line-height: 18px;
		padding-left: 35px;
		position: relative;
		margin: 20px 0px 15px 13px;
	}

	.plan-item .plan-head::before {
		content: '';
		position: absolute;
		left: 7px;
		top: 0px;
		width: 20px;
		height: 18px;
		background-image: url(../../../../assets/imgs/pc//user/img_gondi@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.plan-item .plan-item-orders > .plan-item-order {
		float: left;
		width: 372px;
		height: 174px;
		margin-right: 25px;
		margin-bottom: 20px;
		background-color: #fff;
		box-shadow: 2px 5px 5px #F0F1F7, -2px -2px 2px #F0F1F7;
		border-radius: 12px;
		box-sizing: border-box;
		padding: 27px;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.plan-item .plan-item-orders > .plan-item-order:nth-child(2n-1) {
		margin-left: 20px;
	}

	.plan-item .plan-item-orders > .plan-item-order.status0 {
		background-color: #EFEFEF;
	}

	.plan-item .plan-item-orders > .plan-item-order.status0::after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		width: 75px;
		height: 76px;
		background-image: url(../../../../assets/imgs/pc//user/img_guoqi@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.plan-item .plan-item-orders > .plan-item-order .name {
		color: #231F20;
		font-size: 20px;
		line-height: 20px;
		font-weight: bold;
	}

	.plan-item .plan-item-orders > .plan-item-order .time {
		font-size: 16px;
		line-height: 14px;
		color: #969696;
		font-weight: 400;
		margin-top: 10px;
	}

	.plan-item .plan-item-orders > .plan-item-order .total {
		font-size: 18px;
		font-weight: 400;
		color: #231F20;
		height: 20px;
		line-height: 20px;
		position: relative;
		margin-top: 44px;
		padding-left: 30px;
	}

	.plan-item .plan-item-orders > .plan-item-order .total::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		background-image: url(../../../../assets/imgs/pc//user/img_gongji@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.plan-item .plan-item-orders > .plan-item-order.status0 .total::before {
		background-image: url(../../../../assets/imgs/pc//user/img_gongjihui@2x.png);
	}

	.plan-item .plan-item-orders > .plan-item-order .free {
		position: absolute;
		left: 172px;
		bottom: 37px;
		height: 20px;
		line-height: 20px;
		font-size: 18px;
		font-weight: 400;
		color: #231F20;
		padding-left: 30px;
	}

	.plan-item .plan-item-orders > .plan-item-order .free::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		background-image: url(../../../../assets/imgs/pc//user/img_shenyu@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.plan-item .plan-item-orders > .plan-item-order.status0 .free::before {
		background-image: url(../../../../assets/imgs/pc//user/img_shenyuhuii@2x.png);
	}

	.plan-item .plan-item-orders > .plan-item-order .btn-detail {
		position: absolute;
		right: 37px;
		top: 22px;
		padding: 7px 12px 7px 11px;
		line-height: 16px;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
		border-radius: 15px;
		cursor: pointer;
		background: linear-gradient(10deg,rgba(29,104,255,1) 0%,rgba(116,162,255,1) 100%);
	}

	.plan-item .plan-item-orders > .plan-item-order.status0 .name,
	.plan-item .plan-item-orders > .plan-item-order.status0 .time,
	.plan-item .plan-item-orders > .plan-item-order.status0 .total,
	.plan-item .plan-item-orders > .plan-item-order.status0 .total {
		color: #969696;
	}

	.plan-item .plan-item-orders > .plan-item-order.status0 .btn-detail {
		background: #fff;
		color: #231F20;
	}

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 16px;
		font-weight: 400;
		color: #99999991;
		margin-top: 30px;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

</style>
