<template>
    <!-- 医疗计划 消费明细 -->
    <div class="dialog" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">消費明細（{{title}}）</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <div class="detail-list">
                <div class="detail-item" v-for="(item, index) in list" :key="index">
                    <span class="doctor-name">醫師：{{item.doctorname}}</span>
                    <span class="clinic-name">{{item.clinicname}}</span>
                    <span class="time">{{item.registerdate}}</span>
                </div>
            </div>
        </div>
        <loading v-if="loading"/>
    </div>
</template> 

<script>
import Loading from '@/layouts/Loading.vue'
export default {
    components: {
        Loading
    },
    props: {
        callback: Function,
        title: '',
        id: Number,
    },
    data() {
        return {
            list: [],
            loading: true,
        }
    },
    mounted: function(){
        this.loadData()
    },
    methods: {
        dismissDialog: function() {
            this.callback && this.callback()
        },
        async loadData() {
            this.loading = true
            var rs = await this.$store.dispatch('getPlanCardDetail', {
                clientId: this.$store.getters.clientId,
                medicalplanitemid: this.id
            })
            this.list = rs
            this.loading = false
        }
    }
}
</script>

<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }
    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 936px;
        height: 680px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        padding-left: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }


.detail-list > .detail-item {
    display: flex;
    flex-direction: row;
    height: 49px;
    background-color: #fff;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
}

.detail-list > .detail-item:nth-child(2n) {
    background-color: #E8E8E8;
}

.detail-list > .detail-item .doctor-name {
    color: #242020;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    padding-left: 56px;
    position: relative;
    width: 253px;
    box-sizing: border-box;
    text-align: left;
}

.detail-list > .detail-item .doctor-name::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(../assets/imgs/pc/user/img_nvys@2x.png); 
}

.detail-list > .detail-item .doctor-name.man::before {
    background-image: url(../assets/imgs/pc/user/img_nanys@2x.png); 
}

.detail-list > .detail-item .clinic-name,
.detail-list > .detail-item .time {
    color: #242020;
    font-size: 18px;
    line-height: 18px;
    width: 300px;
    text-align: left;
}

.detail-list > .detail-item .time {
    flex: 1;
}
</style>